
import { defineComponent, ref, Ref, inject, watch, onUnmounted } from "vue";
import { useI18n } from "vue-i18n";
import { notification } from "ant-design-vue";
import { AxiosStatic } from "axios";
import { VisitPatient } from "../../types";
import { OptionCoreData } from "ant-design-vue/lib/vc-select/interface";
import moment from "moment";

interface VisitPatientLocal {
  patientId: number;
  patientName: string;
}

export const GetDocumentsComponent = defineComponent({
  emits: ["update:modelValue"],
  props: {
    modelValue: {
      type: Boolean,
      required: true,
    },
    visitId: {
      type: String,
      required: true,
    },
    appointmentAt: {
      type: String,
      required: true,
    },
  },

  setup(props, { emit }) {
    const loading = ref(false);
    const singlePatient = ref(false);
    const visible = ref(props.modelValue);
    const { t } = useI18n();
    const http = inject("http") as AxiosStatic;
    const patientList: Ref<VisitPatient> = ref({});
    const selectedPatient: Ref<VisitPatientLocal> = ref({
      patientId: 0,
      patientName: "",
    });
    let getDocumentsTimerInterval: NodeJS.Timeout;
    const getDocumentsTimer = 5000;

    const handleSelectChange = (
      value: VisitPatientLocal["patientName"],
      obj: OptionCoreData
    ) => {
      selectedPatient.value = {
        patientId: (obj.key ?? 0) as number,
        patientName: (obj.value ?? "") as VisitPatientLocal["patientName"],
      };
    };

    const afterClose = () => {
      emit("update:modelValue", false);
    };

    const cancel = (leaveModal = false) => {
      clearInterval(getDocumentsTimerInterval);
      if (!leaveModal) visible.value = false;
      loading.value = false;
    };

    const getDocument = () => {
      loading.value = true;
      clearInterval(getDocumentsTimerInterval);
      getDocumentsTimerInterval = setInterval(async () => {
        let documentResponseObj;
        let documentResponseStatus = 0;
        try {
          const documentResponse = await http.get(
            `/patient-portal/api/doctor-documentation/${props.visitId}/${selectedPatient.value.patientId}`,
            { responseType: "blob" }
          );
          documentResponseObj = documentResponse.data;
          documentResponseStatus = documentResponse.status;
        } catch {
          notification.open({
            message: t("ERROR.4982"),
            class: "error",
          });
          cancel();
        } finally {
          if (documentResponseStatus == 200) {
            const url = window.URL.createObjectURL(
              new Blob([documentResponseObj], {
                type: "application/pdf",
              })
            );
            const link = document.createElement("a");
            link.href = url;
            link.setAttribute(
              "download",
              `${t("GET_DOCUMENT.DOCUMENT_TEXT_SUMMARY")}_${
                props.visitId
              }_${moment(props.appointmentAt).format("YYYY-MM-DD")}`
            );
            document.body.appendChild(link);
            link.click();
            document.body.removeChild(link);
            loading.value = false;
            Object.keys(patientList.value).length > 1 ? cancel(true) : cancel();
          } else if (documentResponseStatus == 202) {
            getDocument();
          } else {
            notification.open({
              message: t("ERROR.4982"),
              class: "error",
            });
            cancel();
          }
        }
      }, getDocumentsTimer);
    };
    const getPatients = async () => {
      try {
        patientList.value = (await http
          .get(`/patient-portal/api/appointments/${props.visitId}/patients`)
          .then((res) => res.data)) as VisitPatient;
      } catch {
        notification.open({
          message: t("ERROR.4982"),
          class: "error",
        });
      } finally {
        selectedPatient.value = {
          patientId: parseInt(Object.keys(patientList.value)[0]),
          patientName: Object.values(patientList.value)[0],
        };
        if (Object.keys(patientList.value).length == 1) {
          getDocument();
        } else {
          singlePatient.value = true;
        }
      }
    };

    watch(
      () => props.modelValue,
      (nv) => {
        visible.value = nv;
        if (nv == true) getPatients();
      }
    );

    onUnmounted(() => {
      clearInterval(getDocumentsTimerInterval);
    });

    return {
      loading,
      visible,
      patientList,
      selectedPatient,
      singlePatient,
      handleSelectChange,
      afterClose,
      t,
      getDocument,
      cancel,
    };
  },
});

export default GetDocumentsComponent;
