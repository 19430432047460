import { toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, renderList as _renderList, Fragment as _Fragment, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createBlock as _createBlock, createVNode as _createVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-749c8732"),n=n(),_popScopeId(),n)
const _hoisted_1 = { key: 0 }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_a_select_option = _resolveComponent("a-select-option")!
  const _component_a_select = _resolveComponent("a-select")!
  const _component_a_col = _resolveComponent("a-col")!
  const _component_a_row = _resolveComponent("a-row")!
  const _component_a_spin = _resolveComponent("a-spin")!
  const _component_a_button = _resolveComponent("a-button")!
  const _component_a_modal = _resolveComponent("a-modal")!

  return (_openBlock(), _createBlock(_component_a_modal, {
    title: _ctx.t('GET_DOCUMENT.HEADER'),
    class: "get-documents",
    visible: _ctx.visible,
    "onUpdate:visible": _cache[2] || (_cache[2] = ($event: any) => ((_ctx.visible) = $event)),
    closable: false,
    maskClosable: false,
    "after-close": _ctx.afterClose
  }, {
    footer: _withCtx(() => [
      (_ctx.singlePatient)
        ? (_openBlock(), _createBlock(_component_a_button, {
            key: 0,
            type: "primary",
            class: "get-documents__submit",
            onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.getDocument())),
            disabled: _ctx.loading
          }, {
            default: _withCtx(() => [
              _createTextVNode(_toDisplayString(_ctx.t("GET_DOCUMENT.DOWNLOAD")), 1 /* TEXT */)
            ]),
            _: 1 /* STABLE */
          }, 8 /* PROPS */, ["disabled"]))
        : _createCommentVNode("v-if", true),
      _createVNode(_component_a_button, {
        type: "secondary",
        class: "get-documents__cancel",
        onClick: _cache[1] || (_cache[1] = ($event: any) => (_ctx.cancel()))
      }, {
        default: _withCtx(() => [
          _createTextVNode(_toDisplayString(_ctx.loading ? _ctx.t("GET_DOCUMENT.CANCEL") : _ctx.t("GET_DOCUMENT.CLOSE")), 1 /* TEXT */)
        ]),
        _: 1 /* STABLE */
      })
    ]),
    default: _withCtx(() => [
      (_ctx.singlePatient)
        ? (_openBlock(), _createElementBlock("p", _hoisted_1, _toDisplayString(_ctx.t("GET_DOCUMENT.DESCRIPTION")), 1 /* TEXT */))
        : _createCommentVNode("v-if", true),
      _createVNode(_component_a_row, null, {
        default: _withCtx(() => [
          _createVNode(_component_a_col, { span: 24 }, {
            default: _withCtx(() => [
              (_ctx.singlePatient)
                ? (_openBlock(), _createBlock(_component_a_select, {
                    key: 0,
                    value: _ctx.selectedPatient.patientName,
                    onSelect: _ctx.handleSelectChange,
                    class: "get-documents__patient-select",
                    disabled: _ctx.loading
                  }, {
                    default: _withCtx(() => [
                      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.patientList, (value, key) => {
                        return (_openBlock(), _createBlock(_component_a_select_option, {
                          value: value,
                          key: key
                        }, {
                          default: _withCtx(() => [
                            _createTextVNode(_toDisplayString(value), 1 /* TEXT */)
                          ]),
                          _: 2 /* DYNAMIC */
                        }, 1032 /* PROPS, DYNAMIC_SLOTS */, ["value"]))
                      }), 128 /* KEYED_FRAGMENT */))
                    ]),
                    _: 1 /* STABLE */
                  }, 8 /* PROPS */, ["value", "onSelect", "disabled"]))
                : _createCommentVNode("v-if", true)
            ]),
            _: 1 /* STABLE */
          })
        ]),
        _: 1 /* STABLE */
      }),
      _createVNode(_component_a_row, null, {
        default: _withCtx(() => [
          _createVNode(_component_a_col, { span: 24 }, {
            default: _withCtx(() => [
              _createVNode(_component_a_spin, {
                class: "get-documents__spiner",
                spinning: _ctx.loading
              }, null, 8 /* PROPS */, ["spinning"])
            ]),
            _: 1 /* STABLE */
          })
        ]),
        _: 1 /* STABLE */
      })
    ]),
    _: 1 /* STABLE */
  }, 8 /* PROPS */, ["title", "visible", "after-close"]))
}