
import { defineComponent, ref, watch, inject } from "vue";
import { useI18n } from "vue-i18n";
import { useStore } from "../store";
import { AxiosStatic } from "axios";

export const MakeDeclarationPopupComponent = defineComponent({
  emits: ["update:modelValue"],
  props: {
    modelValue: {
      type: Boolean,
      required: true,
    },
  },

  setup(props, { emit }) {
    const visible = ref(props.modelValue);
    const checked = ref(false);
    const { t } = useI18n();
    const store = useStore();
    const http = inject("http") as AxiosStatic;
    const afterClose = () => {
      store.commit("setMakeDeclarationPopupStatus", false);
      emit("update:modelValue", false);
    };

    const redirectURL = store.state.runtimeConfig.siteUrl
      ? new URL("/nfz-declaration-type", store.state.runtimeConfig.siteUrl)
      : "";

    watch(
      () => checked.value,
      (nv) => {
        if (nv) {
          visible.value = false;
          http.post(
            `patient-portal/api/patient-profile/disable-popup/make-declaration`
          );
          store.commit("setMakeDeclarationPopupStatus", false);
        }
      }
    );

    watch(
      () => props.modelValue,
      (nv) => {
        visible.value = nv;
      }
    );

    return {
      visible,
      afterClose,
      t,
      checked,
      siteUrl: redirectURL,
    };
  },
});

export default MakeDeclarationPopupComponent;
