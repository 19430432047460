
import { useRouter } from "vue-router";
import {
  defineComponent,
  inject,
  ref,
  reactive,
  onMounted,
  Ref,
  computed,
} from "vue";
import { useI18n } from "vue-i18n";
import { AxiosStatic } from "axios";
import { notification } from "ant-design-vue";
import { SorterType, Visit } from "../../types";
import moment from "moment";
import { visitTypesIconMap } from "../utils/const";
import GetDocuments from "../components/GetDocuments.vue";
import MakeDeclarationPopup from "../components/MakeDeclarationPopup.vue";

import {
  TableState,
  TableStateFilters,
} from "ant-design-vue/es/table/interface";

import {
  InfoCircleOutlined,
  PlusCircleOutlined,
  FileSearchOutlined,
  QuestionCircleOutlined,
  VideoCameraOutlined,
  CommentOutlined,
  DownloadOutlined,
} from "@ant-design/icons-vue";

import { TextToBase64 } from "@hd2/common/src/utils";
import { useStore } from "../store";
import { usePermissions } from "../composable/usePermissions";
import { actions } from "../utils/const";

interface Table {
  columns: TableState["columns"];
  sorter: SorterType;
  data: Array<VisitExt>;
  pagination: TableState["pagination"];
  loading: boolean;
}

interface Notification {
  type: string;
  message: string;
}

export interface VisitExt extends Visit {
  isConsultationDisabled?: boolean;
}

export const VisitsComponent = defineComponent({
  components: {
    InfoCircleOutlined,
    PlusCircleOutlined,
    FileSearchOutlined,
    QuestionCircleOutlined,
    VideoCameraOutlined,
    CommentOutlined,
    DownloadOutlined,
    GetDocuments,
    MakeDeclarationPopup,
  },
  setup() {
    const router = useRouter();
    const http = inject("http") as AxiosStatic;
    const store = useStore();
    const { t } = useI18n();
    const appType = store.state.runtimeConfig.type;
    const { hasPermission } = usePermissions();

    const notifications: Ref<Array<Notification>> = ref([]);
    const loadingNotification: Ref<boolean> = ref(true);
    const getDocumentsVisible = ref(false);
    const makeDeclarationPopupVisible = store.state.popupState.makeDeclaration;
    const getDocumentsVisitId = ref("");
    const table: Table = reactive({
      data: [],
      columns: [
        {
          title: t("VISITS.TABLE.APPOINTMENT_AT"),
          dataIndex: "appointmentAt",
          sorter: true,
          defaultSortOrder: "descend",
          slots: { customRender: "appointmentAt" },
        },
        {
          title: t("VISITS.TABLE.DOCTOR"),
          dataIndex: "doctor",
          sorter: true,
          slots: { customRender: "doctor" },
        },
        {
          title: t("VISITS.TABLE.STATUS"),
          dataIndex: "processStatus",
          customRender: ({ text }: { text: string }) => {
            return t(`VISIT.STATUS.${text}`);
          },
          sorter: true,
        },
        {
          title: t("VISITS.TABLE.APPOINTMENT_TYPE"),
          dataIndex: "visitType",
          slots: { customRender: "visitType" },
          sorter: false,
        },
        {
          title: t("VISITS.TABLE.ACTIONS"),
          key: "actions",
          slots: { customRender: "actions" },
        },
      ],
      sorter: {},
      loading: true,
      pagination: {
        current: 1,
        pageSize: 10,
        total: 0,
      },
    });

    const addDocuments = (visitId: string) => {
      router.push({
        name: "Visit",
        params: { id: visitId, showAddDocuments: "true" },
      });
    };
    const goToTheVisit = (visitId: string) => {
      router.push({ name: "Visit", params: { id: visitId } });
    };
    const goToChat = (visitId: string) => {
      router.push({ name: "Chat", params: { id: visitId } });
    };
    const getVisits = async (
      pagination: TableState["pagination"],
      sorter: SorterType
    ) => {
      table.loading = true;
      let link = `patient-portal/api/appointments?page=${
        pagination?.current ? pagination?.current - 1 : 0
      }&size=${pagination?.pageSize}`;
      if (sorter.field) {
        link += `&sort=${sorter.field},${
          sorter.order === "ascend" ? "ASC" : "DESC"
        }`;
      }
      try {
        const visitRes = await http.get(link);
        table.data = visitRes.data.content.map((visit: Visit) => {
          return {
            ...visit,
            typeIcon: visitTypesIconMap.get(visit.visitType),
          };
        });
        table.pagination = {
          current: visitRes.data.pageable.pageNumber + 1,
          total: visitRes.data.totalElements,
          pageSize: visitRes.data.size,
        };
      } catch {
        notification.open({
          message: t("ERROR.4398"),
          class: "error",
        });
      } finally {
        table.loading = false;
        table.data.forEach((elem) => {
          elem.isConsultationDisabled = true;
          if (elem.processStatus !== "FINISHED") {
            setTimeout(function () {
              elem.isConsultationDisabled = false;
            }, Number(
              moment(elem.appointmentAt).subtract(10, "minutes").diff(moment())
            ));
          }
        });
      }
    };
    const handleTableChange = (
      pagination: TableState["pagination"],
      filters: TableStateFilters,
      sorter: SorterType
    ) => {
      getVisits(pagination, sorter);
    };

    const startConsultation = (visitUrl: string) => {
      router.push({
        name: "Consultation",
        params: { id: TextToBase64.encrypt(visitUrl) },
      });
    };

    const openGetDocuments = (visitId: string) => {
      getDocumentsVisitId.value = visitId;
      getDocumentsVisible.value = true;
    };

    onMounted(async () => {
      loadingNotification.value = true;
      try {
        if (hasPermission(actions.SHOW_NOTIFICATIONS)) {
          const notificationRes = await http.get(
            `patient-portal/api/notification`
          );
          notifications.value = notificationRes.data;
        }
      } catch {
        notification.open({
          message: t("ERROR.4082"),
          class: "error",
        });
      } finally {
        loadingNotification.value = false;
        getVisits(table.pagination, {
          columnKey: "appointmentAt",
          field: "appointmentAt",
          order: "descend",
        });
      }
    });

    return {
      loadingNotification,
      table,
      goToChat,
      goToTheVisit,
      addDocuments,
      handleTableChange,
      notifications,
      appType: computed(() =>
        [
          "PZU",
          "ALLIANZ",
          "INTER",
          "WELBI",
          "TU_ZDROWIE",
          "MEEDY",
          "LUXMED",
        ].includes(appType)
          ? appType
          : "GENERAL"
      ),
      t,
      startConsultation,
      hasPermission,
      actions,
      visitTypesIconMap,
      getDocumentsVisible,
      openGetDocuments,
      getDocumentsVisitId,
      makeDeclarationPopupVisible,
    };
  },
});
export default VisitsComponent;
